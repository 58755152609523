import { isSameDay } from "date-fns";

import { createSlice } from "@reduxjs/toolkit";
import { getStorageItem, setStorageItem, STORAGE_KEYS } from "@sbm/fe-utils";
import { IUser } from "@types";

interface IAuthState {
  userProfile: IUser | null;
  token: string;
}

export const initialStateAuth: IAuthState = {
  userProfile: null,
  token: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialStateAuth,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserProfile: (state, action) => {
      const data = action.payload;

      if (data?.preferableLanguage) {
        setStorageItem(STORAGE_KEYS.lang, data.preferableLanguage);
      }

      const activityTime = getStorageItem(String(data.id));

      if (!activityTime || !isSameDay(new Date(), new Date(activityTime))) {
        setStorageItem(String(data.id), new Date().getTime());
      }

      state.userProfile = data;
    },
  },
});

export const { setUserProfile, setToken } = authSlice.actions;

export * from "./operations";

export default authSlice.reducer;
